import React, { Component } from "react";
import bgTexture from "./images/bg-chevron.png";
import colors from "@deanbarker44/afm-js/src/assets/colors";
import Navbar from "./Navbar";

class PageTemplate extends Component {
  render() {
    const { title, children, showLogInInstead } = this.props;
    return (
      <div style={styles.outside}>
        <Navbar showLogInInstead={showLogInInstead} />
        <div style={styles.bg}></div>
        <div style={styles.wrapper}>
          <div style={styles.inside}>
            <h1 style={styles.title}>{title}</h1>
            <div style={styles.bottom}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  outside: {
    height: "100vh",
    width: "100vw",
    position: "relative",
  },
  label: {
    fontSize: 12,
    color: colors.lightText,
    marginTop: 20,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundImage: `url(${bgTexture})`,
    height: "100vh",
    zIndex: 2,
  },
  title: {
    textAlign: 'center'
  },
  inside: {
    width: 500,
    // height: 270,
    boxShadow: "0 0 12px 8px rgba(0,0,0,.05)",
    backgroundColor: "#fff",
    marginBottom: 100,
    marginTop: "20vh",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
};

export default PageTemplate;
