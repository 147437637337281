import React, { Component } from "react";
import afmLogo from "./images/logo.svg";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <div />
        <img src={afmLogo} style={styles.afmLogo} />
        {this.props.showLogInInstead ? <a href="https://login.afm.io">Log in instead</a> : <div />}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: "#fff",
    boxShadow: "0 0 9px 3px rgba(0,0,0,.05)",
    paddingLeft: 20,
    paddingRight: 20
  },
  afmLogo: {
    height: 40,
  },
};

export default Navbar;
