import React, { Component } from "react";
import afm from "@deanbarker44/afm-js";
import { withRouter, Link } from "react-router-dom";
import { Button, Alert, Input } from "antd";
import colors from "@deanbarker44/afm-js/src/assets/colors";
// import Footer from "components/Footer";
import constants from "@deanbarker44/afm-js/src/constants";
import PageTemplate from "./PageTemplate";

const initialState = {
  errors: [],
  fullName: "",
  shortName: "",
  email: "",
  phoneNumber: "",
  zipCode: "",
  loading: false
};

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  isFormValid = () => {
    let errors = [];
    const { fullName, shortName, email, phoneNumber, zipCode } = this.state;

    if (fullName.length < 2) errors.push("Please enter a valid full name");
    if (shortName.length < 2) errors.push("Please enter a valid short name");
    if (shortName.length > 20) errors.push("Your short name is too long.");
    if (!email.includes("@") || email.length < 2)
      errors.push("Please enter a valid email");
    if (phoneNumber.length < 10)
      errors.push("Please enter a valid phone number");
    if (zipCode.length !== 5)
      errors.push("Please enter a valid 5 digit zip code");

    if (errors.length > 0) {
      this.setState({ errors });
      return false;
    } else {
      this.setState({ errors: [] });
      return true;
    }
  };

  submit = async () => {
    if (this.isFormValid()) {
      const { fullName, shortName, email, phoneNumber, zipCode } = this.state;
      this.setState({loading: true})

      fetch('https://6ho5lq4vg46jpvhglufih532yi0rhxtv.lambda-url.us-east-1.on.aws', {
        method: 'POST',
        body: JSON.stringify({
          message: `register.afm.io form submit: \n
          Name: ${fullName} \n
          Short Name: ${shortName} \n
          Email: ${email} \n
          Phone: ${phoneNumber} \n
          Zip Code: ${zipCode} \n
          `
        })
      })
      .then(result => result.json())
      .then(result => {
       this.setState({loading: false});
       alert("Submitted!")
      })
    
    }
  };

  render() {
    const { loading, errors } = this.state;
    return (
      <PageTemplate showLogInInstead={true} title="Register a new account">
        <Input
          type="text"
          style={styles.input}
          placeholder="Full Name"
          value={this.state.fullName}
          onChange={(x) => this.setState({ fullName: x.target.value })}
        />
        <Input
          type="text"
          style={styles.input}
          placeholder="Prefered Short Name"
          value={this.state.shortName}
          onChange={(x) => this.setState({ shortName: x.target.value })}
        />
        <Input
          type="text"
          style={styles.input}
          placeholder="Email"
          value={this.state.email}
          onChange={(x) =>
            this.setState({ email: x.target.value.toLowerCase() })
          }
        />
        <Input
          type="text"
          style={styles.input}
          placeholder="Phone Number"
          value={this.state.phoneNumber}
          onChange={(x) => this.setState({ phoneNumber: x.target.value })}
        />
        <Input
          type="text"
          style={styles.input}
          placeholder="Zip Code"
          value={this.state.zipCode}
          onChange={(x) => this.setState({ zipCode: x.target.value })}
        />
        <br />

        <Button
          disabled={loading}
          type="primary"
          onClick={this.submit.bind(this)}
          style={{ width: 300, marginTop: 20 }}
          size="large"
          loading={loading}
        >
          Register
        </Button>
        <div style={styles.termsText}>
          By clicking Register, you agree to AFM's{" "}
         <a href='https://register.afm.io/terms.html'>Terms of Service</a>.
        </div>
        {errors.length > 0 ? (
          <div style={styles.errorWrapper}>
            {errors.map((item) => (
              <Alert
                message={item}
                style={styles.error}
                type="error"
                showIcon
              />
            ))}
          </div>
        ) : null}
      </PageTemplate>
    );
  }
}

const styles = {
  termsText: {
    color: colors.lightText,
    marginTop: 5
  },
  col: {
    flex: 0.3,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    marginBottom: 20,
  },
  errorWrapper: {
    paddingTop: 30,
  },
  error: {
    marginBottom: 20,
  }
};

export default RegisterPage;
