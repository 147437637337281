import React, { Component } from "react";
import bgTexture from "./images/bg-chevron.png";
import colors from "@deanbarker44/afm-js/src/assets/colors";
import { Input, Button, message } from "antd";
import Navbar from "./Navbar";
import afm from '@deanbarker44/afm-js';
import constants from '@deanbarker44/afm-js/src/constants';

import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";

message.config({
  top: 100,
  duration: 5,
});

// global.afm = new afm();
// global.afm.login({
//   username: "registrar",
//   password: constants.registrarPassword,
//   ip: `https://nous.afm.io/api`,
//   project: "registrar"
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: window.location.href.includes('login.afm'),
      isRegister: window.location.href.includes("register.afm")
    };
  }


  render() {
    const { isLogin, isRegister } = this.state;
    if(isLogin) return <LoginPage />;
    if(isRegister) return <RegisterPage />;
    return <RegisterPage />;
  }
}


export default App;
