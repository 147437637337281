import React, { Component } from "react";
import bgTexture from "./images/bg-chevron.png";
import colors from "@deanbarker44/afm-js/src/assets/colors";
import { Input, Button, message } from "antd";
import Navbar from "./Navbar";
import afm from '@deanbarker44/afm-js';
import constants from '@deanbarker44/afm-js/src/constants';
import PageTemplate from "./PageTemplate";
import { getDefaults } from '@deanbarker44/afm-js/src/helpers/login';

message.config({
  top: 100,
  duration: 5,
});

// global.afm = new afm();
// global.afm.login({
//   username: "registrar",
//   password: constants.registrarPassword,
//   ip: `https://nous.afm.io/api`,
//   project: "registrar"
// });

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: ''
    };
  }

  async componentDidMount(){
    let existingUsername = await localStorage.getItem('cachedUsername');
    if(existingUsername) this.setState({username: existingUsername.replaceAll(/ /g, '')});
  }

  getValidationErrorMessage = () => {
    let message = undefined
    if(!this.state.username) message = 'Please enter your username first';
    return message
  }

  onPressNext = async () => {
    let validationErrorMessage = this.getValidationErrorMessage();
    if(validationErrorMessage) return message.error(validationErrorMessage)
    this.setState({loading: true});
    let username = this.state.username.replaceAll(/ /g, '');
    let defaults = await getDefaults(username, 'afmLogin', {});

    if(defaults.server){
      localStorage.setItem('cachedUsername', username);
      let url = '';
      url = `https://${defaults.server}/user/login`;
      this.setState({loading: false});
      window.location = url;
    }else{
      alert("Could not find the user: "+username)
      this.setState({loading: false});
    }

    
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onPressNext();
    }
  }

  onChangeUsername = e => {
    let newName = e.target.value.replaceAll(/ /g, ''); 
    this.setState({username: newName})
  }

  render() {
    return (
      <PageTemplate title="Log In">
        <div style={styles.label}>Username</div>
        <Input  size="large"  onChange={this.onChangeUsername} value={this.state.username} onKeyDown={this.onKeyDown} />
        <Button loading={this.state.loading} type="primary" size="large" style={styles.next} onClick={this.onPressNext}>
          Next
        </Button>
      </PageTemplate>
    );
  }
}

const styles = {
  next: {
    width: "100%",
    marginTop: 15
  }
};

export default LoginPage;
